export const getSentryEnvironment = (environment?: string) => {
  switch (environment) {
    case 'test':
      return 'staging'
    case 'development':
      return 'development'
    default:
      return 'production'
  }
}
