import type { ApolloError } from '@apollo/client'

import { notFound } from 'next/navigation'

export const apolloErrorHandler = (error: ApolloError) => {
  // Item not found
  if (error.message.startsWith("Cannot read properties of null (reading '_id')")) {
    notFound()
  } else {
    throw error
  }
}
