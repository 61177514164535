import type { Snack, SnackOrSlug, SnackResponse, SnackType, SnackWithId } from '@/types'
import type { Playable } from '@eo/graphql-types'
import type { DocumentNode } from 'graphql'

import gql from 'graphql-tag'

export const getSnackItemType = (snack: SnackOrSlug) => {
  if (Array.isArray(snack) && snack.some((slug) => slug && slug.includes('_'))) {
    return 'playable'
  }
  if (Array.isArray(snack) && snack.some((slug) => slug && slug.includes('-'))) {
    return 'poll'
  }
  if (snack && typeof snack !== 'string' && 'mid' in snack) {
    return 'playable'
  }
  if (snack && typeof snack !== 'string' && Object.hasOwn(snack, 'modelId')) {
    return 'poll'
  }
  return 'playable'
}

export const getSnackId = (snack: SnackOrSlug): string => {
  if ('id' in snack) {
    return snack.id!
  }
  return (snack as string[])[0]
}

export const getSnackFromResponse = (response: SnackResponse): SnackWithId =>
  'pomsPlayable' in response
    ? (response as { pomsPlayable: Playable }).pomsPlayable
    : response.articles.items[0]

export const getSnackType = (snack: Snack): SnackType => {
  if (!('avType' in snack)) {
    return 'article'
  }

  return snack?.type === 'AUDIO' ? 'audio' : 'video'
}

export const getPlayableSnackFields = (): string => `
  avType
  broadcastChannel
  duration
  hero
  kickerDescription
  title
  description
  id
  shortDescription
  sortDate
  subtitle
  type`

export const getPollSnackFields = (): string => `
  id
  slug
  title
  subtitle
  publishOn
  changedOn
  seoDescription
  socialDescription
  hero {
    author
    description
    cdnFiles {
      url
    }
  }
  environmentId
  link {
    domainBaseUrl
    defaultPath
    pressPath
  }
  content {
    ... on PollElement {
      poll {
        id
        title
        header
        questionType
        options {
          ... on PollOption {
            id
            label
            correctness
          }
        }
      }
    }
  }`

export const getInitialSnackQuery = (item: 'playable' | 'poll'): DocumentNode => {
  if (item === 'playable') {
    return gql`
      query GetPlayable($id: String!) {
        pomsPlayable(id: $id) {
          ${getPlayableSnackFields()}
          descendantOf {
            midRef
            type
          }
        }
      }
    `
  }
  return gql`
    query GetPoll($where: ArticlesWhereInput) {
      articles(where: $where) {
        items {
          ${getPollSnackFields()}
        }
      }
    }
  `
}
