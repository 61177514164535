import type { Article, PollElement } from '@eo/graphql-types'

export function selectFirstMultipleChoicePoll(article?: Article) {
  if (!article || !article.content) return undefined

  const firstPollElement = article.content?.find(
    (item) => item?.__typename === 'PollElement' && item.poll?.options?.length,
  )

  return firstPollElement as PollElement | undefined
}
